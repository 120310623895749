import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
  Card,
  Table,
  notification,
  Select,
  InputNumber,
  AutoComplete,
  Button,
  Row,
  Col,
  Modal,
  Tag,
  PageHeader,
  Input,
  Tooltip,
  Collapse,
} from 'antd';
import imgSrc from '../../../../assets/success-img.png';
import {
  searchMfAPI,
  transactionAPI,
  getMfSummaryAPI,
} from '../../../../utils/apiService';
import { getCookie } from '../../../../utils/cookieService';
import { logSharedResponse, handleErrorMessage } from '../../../../utils';

import './MfTransactions.css';
import { isBetaSdk } from '../../../../utils/sdk';

const { Option } = Select;

const TRANSACTION = 'TRANSACTION';

const initialMfState = {
  isin: '',
  name: '',
  mfId: '',
};

const initialComponentState = {
  mutualfunds: [],
  type: 'BUY',
  price: 100,
  mutualfund: initialMfState,
  mfResult: [],
  visible: false,
  orders: [],
  transactionId: null,
  mfIdentifier: 'Search',
};

const orderColumns = [
  {
    title: 'Trading Symbol',
    dataIndex: 'tradingSymbol',
    key: 'tradingSymbol',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: tag => {
      return (
        <Tag color={tag.toLowerCase() === 'complete' ? 'green' : 'red'}>
          {' '}
          {tag.toUpperCase()}{' '}
        </Tag>
      );
    },
  },
];

class MfTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = initialComponentState;
    this.transactionText = React.createRef();
  }
  triggerOrder = () => {
    const { mutualfunds, notes } = this.state;
    if (mutualfunds.length > 0) {
      transactionAPI(
        getCookie('userID'),
        TRANSACTION,
        {
          assetUniverse: 'MUTUAL_FUND',
          type: 'SECURITIES',
          securities: mutualfunds.map(mf => ({
            isin: mf.isin,
            price: mf.price,
            type: mf.type,
          })),
        },
        notes,
        null,
        isBetaSdk(),
      )
        .then(({ transactionId }) => {
          this.setState({
            transactionId,
          });
          return transactionId;
        })
        .then(transactionId =>
          window.sc.triggerTransaction({
            transactionId,
          }),
        )
        .then(res => {
          const [lastBatch] = res.orderBatches;
          const orders = lastBatch.orders;
          logSharedResponse('Order Response', res);
          this.setState({
            visible: true,
            orders: orders.map(order => ({
              tradingSymbol: order.tradingsymbol,
              status: order.status,
            })),
            receivedNotes: res.notes,
          });
        })
        .catch(err => {
          handleErrorMessage(err);
          console.log(err);
        });
    } else {
      notification['error']({
        message: 'No Mutual Fund added',
        description: 'First add a MF before you can transact',
        placement: 'bottomLeft',
      });
    }
  };

  addStock = () => {
    if (this.state.mutualfund.isin !== '') {
      this.setState(state => {
        const { mutualfunds, mutualfund, type, price } = state;
        return {
          mutualfunds: [
            ...mutualfunds,
            {
              name: mutualfund.name,
              isin: mutualfund.isin,
              mfId: mutualfund.mfId,
              price,
              type,
            },
          ],
          mutualfund: {
            isin: '',
            name: '',
          },
          type: 'BUY',
          mfResult: [],
        };
      });
    } else {
      notification['error']({
        message: 'No mutualfund selected',
        description:
          'Search for mutualfund name and select from dropdown first',
        placement: 'bottomLeft',
      });
    }
  };

  handleTypeChange = type => {
    this.setState({
      type,
    });
  };

  handleIdChange = mfIdentifier => {
    this.setState({
      mfIdentifier,
    });
  };

  handlePriceChange = price => {
    this.setState({ price });
  };

  onMfSelect = mf => {
    const [name, mfId] = mf.split('~');
    getMfSummaryAPI(mfId)
      .then(res => {
        return res.meta.isin;
      })
      .then(isin => {
        this.setState({
          mutualfund: {
            name,
            isin,
            mfId,
          },
        });
      })
      .catch(_ => {
        notification['error']({
          message: 'ISIN data not available',
          description: 'Isin data for the given MF is not available',
          placement: 'bottomLeft',
        });
        this.setState({
          mutualfund: initialMfState,
        });
      });
  };

  handleIsinChange = e => {
    this.setState({
      mutualfund: {
        ...this.state.mutualfund,
        isin: e.target.value?.toUpperCase(),
      },
    });
  };

  handleSwitch = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleSearch = value => {
    this.setState(() => {
      if (value && value.length >= 3) {
        searchMfAPI(value)
          .then(res => res.results)
          .then(mutualfunds => mutualfunds.map(mf => mf.name + '~' + mf.id))
          .then(mfResult =>
            this.setState({
              mfResult,
            }),
          );
      }
    });
  };

  handleDelete = index => {
    this.setState(state => {
      const mutualfunds = [...state.mutualfunds];
      mutualfunds.splice(index, 1);
      return {
        mutualfunds,
      };
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  triggerTransaction = () => {
    window.sc
      .triggerTransaction({
        transactionId: this.state.tidToTrigger,
      })
      .catch(err => {
        handleErrorMessage(err);
      });
  };

  handleTidToTriggerChange = tidToTrigger => {
    this.setState({ tidToTrigger });
  };

  render() {
    const {
      mutualfunds,
      type,
      mfResult,
      tidToTrigger,
      mfIdentifier,
    } = this.state;
    return (
      <div className="container">
        <Card
          title="Create"
          extra={
            <Button type="primary" className="ml16" onClick={this.triggerOrder}>
              {' '}
              Place Order{' '}
            </Button>
          }
          bodyStyle={{
            padding: '12px',
          }}
        >
          <Row type="flex">
            <Col sm={24} style={{ flexGrow: 1 }} id="postback-section">
              <Collapse>
                <Collapse.Panel header="Postback & Trigger Transaction" key="1">
                  <div className="actions-container">
                    <h2 className="action-header">TransactionID</h2>
                    <div className="action-body">
                      <p
                        onClick={e => {
                          const selection = window.getSelection();
                          selection.removeAllRanges();
                          const range = document.createRange();
                          range.selectNode(e.target);
                          selection.addRange(range);
                        }}
                        style={{ overflowWrap: 'anywhere' }}
                      >
                        {this.state.transactionId || 'Not Available'}
                      </p>
                      <Button
                        className="add-stock-btn"
                        type="primary"
                        onClick={() => {
                          const { transactionId } = this.state;
                          if (transactionId) {
                            this.props.history.push(
                              `/postback?transactionId=${transactionId}`,
                            );
                          } else {
                            notification['error']({
                              message: 'No transactionId found',
                              description: 'First trigger a transaction',
                              placement: 'bottomLeft',
                            });
                          }
                        }}
                      >
                        Search Postback
                      </Button>
                    </div>
                    <div style={{ marginTop: '52px' }} className="action-body">
                      <h2 className="action-header">Trigger a transactionID</h2>
                      <div className="add-stock-controller-item">
                        <Input
                          placeholder="Enter Transaction ID"
                          value={tidToTrigger}
                          onChange={e => {
                            this.handleTidToTriggerChange(e.target.value);
                          }}
                        />
                        <Button
                          type="primary"
                          style={{ marginLeft: '16px' }}
                          onClick={this.triggerTransaction}
                        >
                          Trigger
                        </Button>
                      </div>
                    </div>
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Col>
            <Col sm={24} lg={12} style={{ flexGrow: 1 }}>
              <div className="actions-container">
                <h2 className="action-header">Add Mutual Funds</h2>
                <div className="action-body">
                  <div className={'add-stock-table'}>
                    <div className={'add-stock-row'}>
                      <h3 className="add-stock-item add-stock-controller-item-title">
                        MF Identifier
                      </h3>
                      <div className="add-stock-item">
                        <Select
                          className="input-item"
                          value={mfIdentifier}
                          onChange={this.handleIdChange}
                        >
                          <Option value="Search">Search</Option>
                          <Option value="ISIN">ISIN</Option>
                        </Select>
                      </div>
                    </div>
                    <div className={'add-stock-row'}>
                      <h3 className="add-stock-item add-stock-controller-item-title">
                        Mutual Fund
                      </h3>
                      <div className="add-stock-item">
                        <AutoComplete
                          dataSource={mfResult}
                          onSelect={this.onMfSelect}
                          onSearch={this.handleSearch}
                          placeholder="Search Mutual Funds"
                          className="input-item"
                          disabled={this.state.mfIdentifier !== 'Search'}
                        />
                      </div>
                    </div>
                    <div className={'add-stock-row'}>
                      <h3 className="add-stock-item add-stock-controller-item-title">
                        ISIN
                      </h3>
                      <div className="add-stock-item">
                        <Input
                          disabled={this.state.mfIdentifier !== 'ISIN'}
                          value={this.state.mutualfund.isin}
                          onChange={this.handleIsinChange}
                          className="input-item"
                        />
                      </div>
                    </div>
                    <div className={'add-stock-row'}>
                      <h3 className="add-stock-item add-stock-controller-item-title">
                        Amount
                      </h3>
                      <div className="add-stock-item">
                        <InputNumber
                          min={1}
                          defaultValue={100}
                          onChange={this.handlePriceChange}
                          className="input-item"
                        />
                      </div>
                    </div>
                    <div className={'add-stock-row'}>
                      <h3 className="add-stock-item add-stock-controller-item-title">
                        Type
                      </h3>
                      <div className="add-stock-item">
                        <Select
                          className="input-item"
                          value={type}
                          onChange={this.handleTypeChange}
                        >
                          <Option value="BUY">Buy</Option>
                          <Option value="SELL">Sell</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <Button
                    className="add-stock-btn"
                    type="primary"
                    onClick={this.addStock}
                  >
                    {' '}
                    Add{' '}
                  </Button>
                </div>
              </div>
            </Col>

            <Col sm={24} style={{ flexGrow: 1 }}>
              {mutualfunds.length > 0 && (
                <Table
                  columns={[
                    {
                      title: 'Mutual Fund',
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: () => (
                        <Tooltip title="Required fields for creating a transaction">
                          ISIN <span style={{ color: 'red' }}>*</span>
                        </Tooltip>
                      ),
                      dataIndex: 'isin',
                      key: 'isin',
                    },
                    {
                      title: 'Mf Id (TT)',
                      dataIndex: 'mfId',
                      key: 'mfId',
                    },
                    {
                      title: () => (
                        <Tooltip title="Required fields for creating a transaction">
                          Amount <span style={{ color: 'red' }}>*</span>
                        </Tooltip>
                      ),
                      dataIndex: 'price',
                      key: 'price',
                    },
                    {
                      title: () => (
                        <Tooltip title="Required fields for creating a transaction">
                          Type <span style={{ color: 'red' }}>*</span>
                        </Tooltip>
                      ),
                      dataIndex: 'type',
                      key: 'type',
                    },
                    {
                      title: 'Action',
                      key: 'action',
                      render: (_, __, index) => (
                        <Button
                          type="danger"
                          onClick={() => {
                            this.handleDelete(index);
                          }}
                        >
                          Delete
                        </Button>
                      ),
                    },
                  ]}
                  dataSource={[...mutualfunds]}
                  pagination={false}
                  rowKey={'ticker'}
                  size={'small'}
                  scroll={{ x: '100%' }}
                  className="mt8 m-lr-8"
                />
              )}
            </Col>
          </Row>
        </Card>
        <Modal
          title={null}
          footer={null}
          visible={this.state.visible}
          onCancel={this.closeModal}
          style={{ textAlign: 'center' }}
        >
          <div
            style={{
              width: '200px',
              margin: '50px auto 0',
            }}
          >
            <img
              src={imgSrc}
              alt="success"
              style={{
                width: '200px',
              }}
            />
          </div>
          <PageHeader
            title="Order successful for following mutualfunds"
            style={{ textAlign: 'center', marginTop: 20 }}
          />
          <h3
            onClick={e => {
              const selection = window.getSelection();
              selection.removeAllRanges();
              const range = document.createRange();
              range.selectNode(e.target);
              selection.addRange(range);
            }}
            style={{ overflow: 'scroll', marginBottom: '25px' }}
          >
            {this.state.receivedNotes || 'No notes passed'}
          </h3>
          {
            <div style={{ height: '350px' }}>
              <Table
                columns={orderColumns}
                dataSource={this.state.orders}
                pagination={{ pageSize: 4, position: 'top' }}
              />
            </div>
          }
        </Modal>
      </div>
    );
  }
}

export default withRouter(MfTransactions);

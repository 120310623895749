import { Card, Table } from 'antd';
import React from 'react';
import ReactJson from 'react-json-view';

const getColumnList = (list) => {
  if(list){
    const columnKeys = Array.isArray(list)? list: Object.keys(list);
    return columnKeys.map((col, idx) => ({
      title: col,
      dataIndex: col,
      key: `${col}_${idx}`,
      render: val => <p>{`${val}`}</p>,
    }));
  }
};

const PreviewTable = ({ record }) => {
  const statusRecord = record?.state;
  const statusColumns = getColumnList(record?.state);
  const equitiesHoldingRecord = record?.data['equities.holders'];
  const equitiesHoldingColumns = getColumnList(
    record?.data['equities.holders']?.[0],
  );
  const equitiesSummaryRecord = record?.data['equities.summary'];
  const equitiesSummaryColumns = getColumnList([
    'isin',
    'issuerName',
    'maskedAccNumber',
    'currentValue',
    'units',
    'lastTradedPrice',
  ]);
  const equitiesTransaction = record?.data['equities.transactions'];
  const etfHoldingRecord = record?.data['etf.holders'];
  const etfHoldingColumns = getColumnList(record?.data['etf.holders']?.[0]);
  const etfSummaryRecord = record?.data['etf.summary'];
  const etfSummaryColumns = getColumnList([
    'isin',
    'isinDescription',
    'maskedAccNumber',
    'currentValue',
    'nav',
    'units',
  ]);
  const etfTransaction = record?.data['etf.transactions'];

  const renderList = [
    {
      title: 'Equities Holding',
      record: equitiesHoldingRecord,
      col: equitiesHoldingColumns,
    },
    {
      title: 'Equities Summary',
      record: equitiesSummaryRecord,
      col: equitiesSummaryColumns,
    },
    { title: 'ETF Holding', record: etfHoldingRecord, col: etfHoldingColumns },
    { title: 'ETF Summary', record: etfSummaryRecord, col: etfSummaryColumns },
  ];

  return (
    <div>
      <Card
        title="Status"
        bordered={false}
        bodyStyle={{ padding: '24px 0px'}}
      >
        <Table
          dataSource={statusRecord && [statusRecord]}
          columns={statusColumns}
          pagination={false}
          scroll={{ x: true }}
        />
      </Card>
      {renderList.map(item => (
        <Card
          title={item.title}
          bordered={false}
          bodyStyle={{ padding: '24px 0px'}}
          key={item.title}
        >
          <Table
            dataSource={item.record}
            columns={item.col}
            pagination={false}
            scroll={{ x: true }}
          />
        </Card>
      ))}
      <Card
        title={'Equities Transaction'}
        bordered={false}
        bodyStyle={{ padding: '24px 0px' }}
      >
        <ReactJson src={equitiesTransaction} collapsed />
      </Card>

      <Card
        title={'ETF Transaction'}
        bordered={false}
        bodyStyle={{ padding: '24px 0px' }}
      >
        <ReactJson src={etfTransaction} collapsed />
      </Card>
    </div>
  );
};

export default PreviewTable;

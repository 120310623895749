import React, { useState } from 'react';
import { MessageFilled, CopyFilled, CheckCircleOutlined } from '@ant-design/icons';
import { List, Typography, Tooltip } from 'antd';
import { copyToClipboard } from '../utils';
import './Progress.css';

const Progress = ({ consentData, postMessages }) => {
  const [showMessages, setShowMessages] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className="content">
      <iframe
        src={consentData.url}
        width={'100%'}
        height={'600px'}
        title="Consent Document"
        style={{ border: 0 }}
      />
      <div className="msg-container">
        <MessageFilled
          className="msg-btn"
          onClick={() => setShowMessages(prev => !prev)}
        />
        <List
          className="msg-list"
          style={{
            transform: showMessages ? 'scale(1)' : 'scale(0)',
          }}
          size="small"
          header={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Tooltip title={'Journey Id ' + consentData.journeyId}>
                <div style={{ fontWeight: 'bold' }}>PostMessages</div>
              </Tooltip>
              {isCopied ? (
                <div onMouseLeave={()=>setIsCopied(false)}>
                    <CheckCircleOutlined/>
                </div>
              ) : (
                <CopyFilled
                  onClick={() => {
                    copyToClipboard(consentData.journeyId);
                    setIsCopied(true);
                  }}
                />
              )}
            </div>
          }
          bordered
          dataSource={postMessages}
          renderItem={({ time, data }) => (
            <List.Item>
              <Typography.Text mark>{time}</Typography.Text> {data}
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default Progress;

import React, { useState, useCallback, useRef } from 'react';
import { Card, Button, Row, Col, Modal } from 'antd';
import './AAJourney.css';
import { showErrorToast, getCurrentTime, postMessageListener, wait } from './utils';
import {
  createConsentJourney,
  fetchConsentResults,
} from '../../../../utils/apiService';
import Progress from './Progress';
import CreateConsentForm from './CreateConsentForm';
import AssetDataForm from './AssetDataForm';
import PreviewTable from './PreviewTable';

const JourneyStatus = {
  READY: 'ready',
  PROGRESS: 'progress',
};

const LoadingStatus = {
  PREPROGRESS:'preprogress',
  PREFETCH: 'prefetch',
  IDLE: 'idle'
};

const AAEvents = {
  ASK_PARAMS : 'ASK_PARAMS',
  SET_ANALYTICS_PARAMS: 'SET_ANALYTICS_PARAMS',
  APP_SUCCESS : 'app_success'
};

const AAJourney = () => {
  const [journeyStatus, setJourneyStatus] = useState(JourneyStatus.READY);
  const [postMessages, setPostMessages] = useState([]);
  const [loadingStage, setLoadingStage] = useState(LoadingStatus.IDLE);
  const [consentData, setConsentData] = useState({ journeyId: '', url: '' });
  const prefillJourneyId = useRef('');

  const updatePostMessages = useCallback(event => {
    if (event.origin !== window.location.origin) {
      const { eventLabel, metadata } = event.data;

      switch (eventLabel) {
        case AAEvents.ASK_PARAMS:
          event.source.postMessage(
            {
              type: AAEvents.SET_ANALYTICS_PARAMS,
              metadata: { gatewayName: 'gatewaydemo-stag', deviceType: 'web' },
            },
            event.origin,
          );
          break;

        case AAEvents.APP_SUCCESS:
          (async () => {
            await wait(3000);
            closeJourney();
          })();
          break;

        default:
          setPostMessages(prev => [
            { time: getCurrentTime(), data: eventLabel },
            ...prev,
          ]);
          break;
      }
    }
  }, []);

  const handleConsentResponse = res => {
    const { journeyId, vendorData } = res.data;
    if (journeyId && vendorData?.redirectUrl) {
      setConsentData({ journeyId, url: vendorData.gwRedirectUrl });
      setJourneyStatus(JourneyStatus.PROGRESS);
      postMessageListener(true, updatePostMessages);
    }
  };

  const handleStartJourney = async (userId, phone, pan) => {
    try {
      setLoadingStage(LoadingStatus.PREPROGRESS);
      const response = await createConsentJourney(userId, phone, pan);
      handleConsentResponse(response);
    } catch (err) {
      showErrorToast('Consent Creation Error', err.response?.data);
    } finally {
      setLoadingStage(LoadingStatus.IDLE);
    }
  };

  const handleDataFetch = async (journeyId) => {
    try {
      setLoadingStage(LoadingStatus.PREFETCH);
      const res = await fetchConsentResults(journeyId);
       Modal.info({
         title: 'Asset Record',
         content: (
           <div className="response-data-content">
             <PreviewTable record = {res.data}/>
           </div>
         ),
         className: 'response-data-modal',
         width: '100vw',
       });
    } catch (err) {
      showErrorToast('Data Fetch Error', err.response?.data);
    } finally {
      setLoadingStage(LoadingStatus.IDLE);
    }
  };

  const closeJourney = () => {
    setJourneyStatus(JourneyStatus.READY);
    setPostMessages([]);
    setConsentData(prev => {
      prefillJourneyId.current = prev.journeyId;
      return { journeyId: '', url: '' };
    });
    postMessageListener(false, updatePostMessages);
  };

  const getScene = () => {
    switch (journeyStatus) {
      case 'progress':
        return (
          <Progress consentData={consentData} postMessages={postMessages} />
        );

      default:
        return (
          <Row>
            <Col sm={24} xl={12}>
              <div className="actions-container">
                <div>
                  <h1 className="action-head">Consent Creation Journey</h1>
                  <CreateConsentForm
                    onSubmit={handleStartJourney}
                    loading={loadingStage === LoadingStatus.PREPROGRESS}
                  />
                </div>
              </div>
            </Col>
            <Col sm={24} xl={12} style={{ flexGrow: 1, alignItems: 'stretch' }}>
              <div className="actions-container">
                <Row className="mb12">
                  <h1 className="action-head">Get Asset Data</h1>
                  <AssetDataForm
                    onSubmit={handleDataFetch}
                    loading={loadingStage === LoadingStatus.PREFETCH}
                    defaultJourneyIdRef={prefillJourneyId}
                  />
                </Row>
              </div>
            </Col>
          </Row>
        );
    }
  };

  return (
    <div className="container">
      <Card
        title="Account Aggregator Journey"
        extra={
          journeyStatus !== 'ready' && (
            <Button
              type="danger"
              ghost
              style={{ marginLeft: 4 }}
              onClick={closeJourney}
            >
              Reset
            </Button>
          )
        }
      >
        {getScene()}
      </Card>
    </div>
  );
};

export default AAJourney;

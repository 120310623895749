import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import './CreateConsentForm.css';

const CreateConsentForm = ({ form, onSubmit, loading }) => {
  const { getFieldDecorator, validateFields } = form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { user_id, phone, pan } = values;
        onSubmit(user_id, phone, pan);
      }
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="User Id" className="form-item">
        {getFieldDecorator('user_id', {
          rules: [
            {
              required: true,
              message: 'User Identifier is required !',
            },
          ],
          validateTrigger: 'onSubmit',
        })(
          <Input
            placeholder="e7edad29-52bf-4704-b722-e973bbc6bff3"
            className="input-item"
          />,
        )}
      </Form.Item>

      <Form.Item label="Phone" className="form-item">
        {getFieldDecorator('phone', {
          rules: [
            {
              required: true,
              message: 'Phone Number is required !',
            },
          ],
          validateTrigger: 'onSubmit',
        })(<Input type="number" placeholder="9876543210" />)}
      </Form.Item>

      <Form.Item label="PAN" className="form-item">
        {getFieldDecorator('pan', {
          rules: [
            {
              required: true,
              message: ' PAN is required',
            },
            {
              pattern: /^[A-Z]{3}P[A-Z][0-9]{4}[A-Z]$/,
              message: 'Invalid PAN',
            },
          ],
          validateTrigger: 'onSubmit',
          normalize: (value) => value?.toUpperCase(),
        })(<Input placeholder="NZYPS9602F" className="input-item" />)}
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button
          className="btn"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Start Journey
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedCreateConsentForm = Form.create({ name: 'create-consent' })(
  CreateConsentForm,
);

export default WrappedCreateConsentForm;

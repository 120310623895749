import { notification } from 'antd';
import { errorWithDetails } from '../../../../utils';

export function getCurrentTime() {
  const date = new Date();
  const options = {
    timeZone: 'Asia/Kolkata',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(date);
}

export const postMessageListener = (toggle, callback) => {
  if (toggle) {
    window.addEventListener('message', callback);
  } else {
    window.removeEventListener('message', callback);
  }
};

export const copyToClipboard = async text => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error('Failed to copy text: ', error);
  }
};

export const showErrorToast = (message, err) => {
  return notification.error({
    message,
    description: errorWithDetails('Something went wrong', err || ''),
    duration: 3,
    placement: 'bottomRight',
  });
};

export const wait = time => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve();
    }, time),
  );
};
